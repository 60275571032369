/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Reset default margin and padding for all elements */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Apply the Roboto font to the entire page */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f0f0f0;
  color: #333; /* Default text color */
}

/* Main container styling */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

/* Mediathek grid */
.mediathek {
  display: flex;
  flex-wrap: wrap; /* Ensure items wrap to the next row on smaller screens */
  gap: 20px;
  max-width: 1200px; /* Limit maximum width for content */
  margin: 0 auto; /* Center the grid horizontally */
}

/* Media item styling */
.media {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.2s ease-in-out; /* Smooth hover effect */
  min-width: 300px; /* Set a minimum width for each item */
}

.media:hover {
  transform: scale(1.05); /* Enlarge on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow on hover */
}

/* Add button styles */
.add-button {
  background-color: #4CAF50; /* Green color for add button */
  color: #fff; /* White text color */
  border: none;
  padding: 15px 30px; /* Increase padding for a bigger button */
  border-radius: 4px;
  cursor: pointer;
  margin: 20px auto; /* Center the button horizontally and add spacing */
  display: block; /* Ensure it takes the full width of the container */
  transition: background-color 0.2s ease-in-out; /* Smooth hover effect */
}

.add-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

/* Center the Add component */
.add-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Form styles */
.form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
}

/* Radio button styles */
.radio-label {
  display: inline-block;
  margin-right: 10px;
}

/* Input field styles */
.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}


/* Styling for the filter select */
.filter {
    margin-bottom: 20px; /* Add some spacing below the select */
  }
  
  /* Style the label */
  .filter label {
    font-weight: bold; /* Make the label bold */
    margin-right: 10px; /* Add some spacing between the label and select */
  }
  
  /* Style the select element */
  .filter select {
    padding: 10px; /* Add padding to the select for better appearance */
    border: 1px solid #ddd; /* Add a border */
    border-radius: 4px; /* Add border radius */
    width: 150px; /* Set a specific width */
    cursor: pointer; /* Show a pointer cursor on hover */
  }
  
  /* Style select options (if needed) */
  .filter select option {
    font-weight: normal; /* Reset the font weight for options if needed */
    background-color: #fff; /* Set the background color for options */
    color: #333; /* Set the text color for options */
  }
  
  /* Style select on hover (if needed) */
  .filter select:hover {
    border-color: #999; /* Change border color on hover */
  }
  
  /* Style select when focused (if needed) */
  .filter select:focus {
    outline: none; /* Remove the default focus outline */
    border-color: #2196F3; /* Add a custom border color when focused */
  }
  